import React from 'react';
import { useNavigate } from 'react-router-dom';
import './MintDetails.css';
import '../App.css';

const MintDetails = () => {
  const navigate = useNavigate();

  return (
    <div className="App app-background">
      <div className="mint-details-container">
        <div className="mint-details-content">
          <h1>Mint Details</h1>
          <div className="mint-info">
            <p>Supply Info -
            There will be 2222 HyperDogs. 1,422 of them will be given free as OG mint and airdrop.</p>
            <p>of the 800 sold public and FCFS, 50% of ALL revenue will be donated to aspca.org</p>
            <h2>Who Is Eligible?</h2>
            <p>OG (Free) - 
            Any HL ECO holder that has submitted wallet in discord.</p>
            <p>FCFS - 
            Any holder of the following collection, will be eligible for FCFS Mint.</p>
            <p>- <a href="https://drip.trade/collections/hypio">Hypio</a>, <a href="https://drip.trade/collections/gems">Gems</a>, <a href="https://drip.trade/collections/hypers">Hypers</a>, <a href="https://drip.trade/collections/trippy-wolves">Trippy Wolves</a>, <a href="https://drip.trade/collections/tiny-hyper-cats">TinyHyperCats</a>, <a href="https://drip.trade/collections/purrtardio">Purrtardio</a>, <a href="https://drip.trade/collections/hyper-frogs">HyperFrogs</a>, <a href="https://drip.trade/collections/mechacats">MechaCats</a>, and <a href="https://drip.trade/collections/odd-otties">Otties</a></p>
            <p>Public Phase - Anyone is eligible to mint.</p>
            <h2>Wen and How</h2>
            <h3>Mint Price</h3>
            <p>OG = Free <br></br> FCFS = .4 HYPE<br></br>Public = .5 HYPE</p>
            <p>Mint Date ~ 18th April 2025 (not set in stone)</p>
            <p>Mint Link - <a href="none yet" target="_blank" rel="noopener noreferrer">https://none yet/</a></p>
          </div>
          <button 
            className="wallet-checker-button"
            onClick={() => navigate('/')}
          >
            Back to Wallet Checker
          </button>
        </div>
      </div>
      <div className="social-links">
                <a href="https://x.com/hyperdogshl" target="_blank" rel="noopener noreferrer" className="social-link x">
                  <svg viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
                    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
                  </svg>
                </a>
                <a href="https://discord.gg/MrskFcRHPy" target="_blank" rel="noopener noreferrer" className="social-link discord">
                  <svg viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
                    <path d="M20.317 4.37a19.791 19.791 0 0 0-4.885-1.515a.074.074 0 0 0-.079.037c-.21.375-.444.864-.608 1.25a18.27 18.27 0 0 0-5.487 0a12.64 12.64 0 0 0-.617-1.25a.077.077 0 0 0-.079-.037A19.736 19.736 0 0 0 3.677 4.37a.07.07 0 0 0-.032.027C.533 9.046-.32 13.58.099 18.057a.082.082 0 0 0 .031.057a19.9 19.9 0 0 0 5.993 3.03a.078.078 0 0 0 .084-.028a14.09 14.09 0 0 0 1.226-1.994a.076.076 0 0 0-.041-.106a13.107 13.107 0 0 1-1.872-.892a.077.077 0 0 1-.008-.128a10.2 10.2 0 0 0 .372-.292a.074.074 0 0 1 .077-.01c3.928 1.793 8.18 1.793 12.062 0a.074.074 0 0 1 .078.01c.12.098.246.198.373.292a.074.074 0 0 1-.006.127a12.299 12.299 0 0 1-1.873.892a.077.077 0 0 0-.041.107c.36.698.772 1.362 1.225 1.993a.076.076 0 0 0 .084.028a19.839 19.839 0 0 0 6.002-3.03a.077.077 0 0 0 .032-.054c.5-5.177-.838-9.674-3.549-13.66a.061.061 0 0 0-.031-.03zM8.02 15.33c-1.183 0-2.157-1.085-2.157-2.419c0-1.333.956-2.419 2.157-2.419c1.21 0 2.176 1.096 2.157 2.42c0 1.333-.956 2.418-2.157 2.418zm7.975 0c-1.183 0-2.157-1.085-2.157-2.419c0-1.333.955-2.419 2.157-2.419c1.21 0 2.176 1.096 2.157 2.42c0 1.333-.946 2.418-2.157 2.418z"/>
                  </svg>
                </a>
              </div>
    </div>
  );
};

export default MintDetails;
