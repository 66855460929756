import React, { useState } from 'react';
import { ethers } from 'ethers';
import { OG_WALLETS, FCFS_WALLETS } from '../config/walletLists';
import { Link } from 'react-router-dom';

function WalletChecker() {
  const [walletAddress, setWalletAddress] = useState('');
  const [ogStatus, setOgStatus] = useState(null);
  const [fcfsStatus, setFcfsStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const checkWallet = async () => {
    try {
      setLoading(true);
      setError('');
      
      if (!ethers.utils.isAddress(walletAddress)) {
        throw new Error('Invalid Ethereum address');
      }

      // Convert input address to lowercase for comparison
      const normalizedAddress = walletAddress.toLowerCase();

      // Check OG status - convert all addresses to lowercase for comparison
      const isOG = OG_WALLETS.some(addr => addr.toLowerCase() === normalizedAddress);
      setOgStatus(isOG);

      // Check FCFS status - convert all addresses to lowercase for comparison
      const isFCFS = FCFS_WALLETS.some(addr => addr.toLowerCase() === normalizedAddress);
      setFcfsStatus(isFCFS);

      // Log for debugging
      console.log('Input address:', normalizedAddress);
      console.log('OG Wallets:', OG_WALLETS);
      console.log('FCFS Wallets:', FCFS_WALLETS);

    } catch (err) {
      setError(err.message);
      setOgStatus(null);
      setFcfsStatus(null);
    } finally {
      setLoading(false);
    }
  };

  const getEligibilityMessage = () => {
    if (ogStatus && fcfsStatus) {
      return "OG (Free) and FCFS";
    } else if (ogStatus) {
      return "OG (Free)";
    } else if (fcfsStatus) {
      return "FCFS";
    }
    return null;
  };

  const isEligible = ogStatus || fcfsStatus;

  return (
    <div className="wallet-checker">
      <div className="input-group">
        <input
          type="text"
          value={walletAddress}
          onChange={(e) => setWalletAddress(e.target.value)}
          placeholder="Enter wallet address"
          className="wallet-input"
        />
        <button 
          onClick={checkWallet}
          disabled={loading}
          className="check-button"
        >
          {loading ? 'Checking...' : 'Check Status'}
        </button>
      </div>
      
      {error && <p className="error">{error}</p>}
      
      <div className="status-results">
        <div className="status-box">
          <h3>OG Status (Free Mint)</h3>
          {ogStatus !== null && (
            <p className={`result ${ogStatus ? 'success' : 'failure'}`}>
              {ogStatus 
                ? '✅ Eligible for OG (Free) Mint' 
                : '❌ Not eligible for OG Mint'}
            </p>
          )}
        </div>

        <div className="status-box">
          <h3>FCFS Status</h3>
          {fcfsStatus !== null && (
            <p className={`result ${fcfsStatus ? 'success' : 'failure'}`}>
              {fcfsStatus 
                ? '✅ Eligible for FCFS Mint' 
                : '❌ Not eligible for FCFS Mint'}
            </p>
          )}
        </div>
      </div>

      {isEligible && (
        <div className="congrats-message">
          <h2>🎉 Congratulations!</h2>
          <p>You are eligible for {getEligibilityMessage()} mint!</p>
          <p>Join our Discord for updates and follow Hyperdogs on X!</p>
          <div className="social-buttons">
            <a 
              href="https://x.com/hyperdogshl" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="social-link x"
            >
              <svg viewBox="0 0 24 24" width="20" height="20" fill="currentColor">
                <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
              </svg>
            </a>
            <a 
              href="https://discord.gg/MrskFcRHPy" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="social-link discord"
            >
              <svg viewBox="0 0 24 24" width="20" height="20" fill="currentColor">
                <path d="M20.317 4.37a19.791 19.791 0 0 0-4.885-1.515a.074.074 0 0 0-.079.037c-.21.375-.444.864-.608 1.25a18.27 18.27 0 0 0-5.487 0a12.64 12.64 0 0 0-.617-1.25a.077.077 0 0 0-.079-.037A19.736 19.736 0 0 0 3.677 4.37a.07.07 0 0 0-.032.027C.533 9.046-.32 13.58.099 18.057a.082.082 0 0 0 .031.057a19.9 19.9 0 0 0 5.993 3.03a.078.078 0 0 0 .084-.028a14.09 14.09 0 0 0 1.226-1.994a.076.076 0 0 0-.041-.106a13.107 13.107 0 0 1-1.872-.892a.077.077 0 0 1-.008-.128a10.2 10.2 0 0 0 .372-.292a.074.074 0 0 1 .077-.01c3.928 1.793 8.18 1.793 12.062 0a.074.074 0 0 1 .078.01c.12.098.246.198.373.292a.074.074 0 0 1-.006.127a12.299 12.299 0 0 1-1.873.892a.077.077 0 0 0-.041.107c.36.698.772 1.362 1.225 1.993a.076.076 0 0 0 .084.028a19.839 19.839 0 0 0 6.002-3.03a.077.077 0 0 0 .032-.054c.5-5.177-.838-9.674-3.549-13.66a.061.061 0 0 0-.031-.03zM8.02 15.33c-1.183 0-2.157-1.085-2.157-2.419c0-1.333.956-2.419 2.157-2.419c1.21 0 2.176 1.096 2.157 2.42c0 1.333-.956 2.418-2.157 2.418zm7.975 0c-1.183 0-2.157-1.085-2.157-2.419c0-1.333.955-2.419 2.157-2.419c1.21 0 2.176 1.096 2.157 2.42c0 1.333-.946 2.418-2.157 2.418z"/>
              </svg>
            </a>
          </div>
          <div style={{ marginTop: '1rem' }}>
            <Link to="/mint-details" className="mint-details-link">
              View Mint Details
            </Link>
            <a 
              href="https://twitter.com/intent/retweet?tweet_id=1910138217598517458" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="retweet-link"
            >
              Retweet to Support
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default WalletChecker; 